import { Select, SelectItem } from '@tremor/react';

export default function WebsiteSelect({
  websites,
  websiteId,
  onWebsiteIdChange,
  className,
}: {
  websites: { name: string; id: string }[];
  websiteId: string;
  onWebsiteIdChange: (websiteId: string) => void;
  className?: string;
}) {
  return (
    <Select className={className} value={websiteId} onValueChange={onWebsiteIdChange}>
      {websites.map(website => (
        <SelectItem key={website.id} value={website.id}>
          {website.name}
        </SelectItem>
      ))}
    </Select>
  );
}
