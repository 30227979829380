import { Card } from '@tremor/react';
import { Link, useLocation } from 'react-router-dom';
import { useGetGoogleSignUpURL } from '../../requests/api/auth/sign-up';
import { GOOGLE_ACTIONS, GOOGLE_ACTION_LABEL } from '../../utils';
import { RiGoogleFill } from '@remixicon/react';
import { Button, Footer, Logo, WebviewCallout } from '../../components';
import { isWebviewUnsupportedByGoogleOAuth } from 'google-oauth-webview';
import { productAnalytics } from '../../external-services';

export default function SignUpPage() {
  const { state } = useLocation();
  const getGoogleSignUpURL = useGetGoogleSignUpURL();

  async function signUpWithGoogle() {
    try {
      const googleSignInURL = await getGoogleSignUpURL();
      productAnalytics.sendEvent('start_sign_up');
      localStorage.setItem(GOOGLE_ACTION_LABEL, GOOGLE_ACTIONS.SIGN_UP);
      window.location.href = googleSignInURL;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col px-4 lg:px-6">
      <div className="grow" />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Card className="flex min-h-96 flex-col justify-between space-y-4 shadow-lg">
          <div />
          <Logo className="mx-auto h-10" />
          <div className="space-y-2">
            <h3 className="text-center text-tremor-title font-semibold text-tremor-content-strong">
              Create an account
            </h3>
            <p className="text-center text-tremor-default text-tremor-content">
              Already have an account?{' '}
              <Link
                to="/sign-in"
                className="text-tremor-default font-medium text-tremor-brand hover:text-tremor-brand-emphasis"
              >
                Sign in
              </Link>
            </p>
          </div>
          {isWebviewUnsupportedByGoogleOAuth(navigator.userAgent) && (
            <WebviewCallout messageType="sign-up" />
          )}
          {state?.signUpFailed && (
            <div className="text-center text-tremor-default text-red-700">
              {state.errorReason === 'user_already_exists' ? (
                <span>
                  A user with this Google account already exists. <br />
                  Try{' '}
                  <Link
                    to="/sign-in"
                    className="text-tremor-default underline hover:text-red-800"
                  >
                    signing in
                  </Link>{' '}
                  instead.
                </span>
              ) : (
                'Impossible to create an account.'
              )}
            </div>
          )}
          <Button
            block
            variant="primary"
            className="mx-auto h-11 max-w-64"
            icon={RiGoogleFill}
            onClick={signUpWithGoogle}
          >
            Sign up with Google
          </Button>
          <div />
        </Card>
      </div>
      <div className="grow" />
      <Footer />
    </div>
  );
}
