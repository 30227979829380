import { RiGlobalLine } from '@remixicon/react';
import { Button } from '../../../../components';
import { SetUpCard } from '../common';

export default function NoPropertyCard() {
  // TODO: use a <Link> component instead of window.open
  function openTutorial() {
    window.open(
      'https://www.linito.io/docs/google-analytics-ga4/setup/create-account',
      '_blank',
    );
  }

  return (
    <SetUpCard>
      <div className="flex justify-center">
        <RiGlobalLine className="h-14 w-14" />
      </div>
      <h4 className="mt-4 text-tremor-metric font-semibold text-tremor-content-strong">
        Create a GA4 property
      </h4>
      <p className="mt-3 text-tremor-default leading-6 text-tremor-content">
        We found a Google Analytics account but no <em>properties</em>.
        <br />
        Follow this tutorial to create one.
        <br />
        <br />
        Not sure what a <em>property</em> is? No worries, we'll explain it in the
        tutorial.
      </p>
      <Button variant="primary" onClick={openTutorial} className="mt-6">
        Open tutorial
      </Button>
    </SetUpCard>
  );
}
