import { useMakeAuthenticatedGetRequest } from '../requests.hooks';

export interface Ga4Account {
  id: string;
  name: string;
}

export interface Website {
  id: string;
  name: string;
}

export interface WebsiteConfiguration {
  summary: {
    timeZone: string;
    timeZoneConfigurationUrl: string;
    websiteURLsAndTrackingCodeIds: {
      url: string | null;
      dataStreamConfigurationUrl: string;
      trackingCodeId: string | null;
    }[];
    minimumDataRetentionTime: string;
    retentionTimeConfigurationUrl: string;
    hasReceivedEventsInLast30Minutes: boolean;
  };
  diagnostic: {
    hasWebDataStreamBeenFound: boolean;
    dataStreamConfigurationUrl: string;
    isRetentionTimeSetUpCorrectly: boolean;
    retentionTimeConfigurationUrl: string;
    hasReceivedEventsInLast30Minutes: boolean;
  };
  ga4Configuration: {
    account: {
      id: string;
      name: string;
      createdAt: string;
    };
    property: {
      id: string;
      name: string;
      timeZone: string;
      createdAt: string;
      eventDataRetentionTime: string;
      userDataRetentionTime: string;
      isUserDataResetOnNewActivity: boolean;
      hasReceivedEventsYesterday: boolean;
      hasReceivedEventsInLast30Minutes: boolean;
    };
    dataSteams: {
      id: string;
      name: string;
      type: string;
      websiteUri: string | null;
      websiteMeasurementId: string | null;
      createdAt: string;
    }[];
  };
}

export function useGetWebsites(): () => Promise<{
  websites: Website[];
  websitesGroupedByGa4Account: {
    ga4Account: Ga4Account;
    websites: Website[];
  }[];
  isUserConnectedToGoogleAnalytics: boolean;
}> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async () => {
    const { data } = await makeAuthenticatedGetRequest('/api/websites');
    return data;
  };
}

export function useGetWebsite(): ({
  websiteId,
}: {
  websiteId: string;
}) => Promise<WebsiteConfiguration> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async ({ websiteId }) => {
    const { data } = await makeAuthenticatedGetRequest(
      `/api/websites/${websiteId}/configuration`,
    );
    return data;
  };
}
