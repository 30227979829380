import { Link, useOutletContext } from 'react-router-dom';
import { SectionContext } from '../section-container';
import { WebsiteSelect } from '../../../components';
import { SummaryCard } from './summary-card';
import { useWebsiteConfiguration } from './common';
import { DiagnosticCard } from './diagnostic-card';

export default function ConfigurationSection() {
  const { websiteId, websites, setWebsiteId } = useOutletContext<SectionContext>();
  const websiteConfiguration = useWebsiteConfiguration({
    websiteId,
    queryKey: 'websiteConfiguration',
  });

  return (
    <section className="space-y-6">
      <div className="flex min-h-10 flex-col items-center gap-4 lg:flex-row">
        <WebsiteSelect
          className="w-full lg:w-80"
          websites={websites}
          websiteId={websiteId}
          onWebsiteIdChange={setWebsiteId}
        />
        <div className="hidden grow lg:block" />
        <Link
          to="https://www.linito.io/docs/google-analytics-ga4/setup/create-account"
          target="_blank"
          className="text-tremor-default font-medium text-tremor-brand hover:text-tremor-brand-emphasis"
        >
          Add a new website
        </Link>
      </div>
      <div className="grid gap-6 lg:grid-cols-2">
        <SummaryCard websiteConfiguration={websiteConfiguration} />
        <DiagnosticCard websiteConfiguration={websiteConfiguration} />
      </div>
    </section>
  );
}
