import { useGetGa4ConnectURL } from '../../../../requests/api/ga4.requests';
import { GOOGLE_ACTIONS, GOOGLE_ACTION_LABEL } from '../../../../utils/constants';
import { useLocation } from 'react-router-dom';
import { isWebviewUnsupportedByGoogleOAuth } from 'google-oauth-webview';
import { Button, WebviewCallout } from '../../../../components';
import { productAnalytics } from '../../../../external-services';
import ga4Logo from './images/ga4_logo.svg';
import { SetUpCard } from '../common';

export default function Ga4ConnectorCard() {
  const getGa4ConnectURL = useGetGa4ConnectURL();
  const { state } = useLocation();
  const { pathname } = useLocation();

  async function connectToGa4() {
    try {
      const googleSignInURL = await getGa4ConnectURL();
      productAnalytics.sendEvent('start_connect_to_ga4');
      localStorage.setItem(GOOGLE_ACTION_LABEL, GOOGLE_ACTIONS.CONNECT_TO_GA4);
      localStorage.setItem('redirectPath', pathname);
      window.location.href = googleSignInURL;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <SetUpCard>
      <div className="flex justify-center">
        <img src={ga4Logo} alt="Google Analytics logo" className="w-14" />
      </div>
      <h4 className="mt-6 text-tremor-metric font-semibold text-tremor-content-strong">
        Get Started
      </h4>
      <p className="mt-3 text-tremor-default leading-6 text-tremor-content">
        Don't have a Google Analytics account set up yet?
        <br />
        No worries, we'll help you configure one.
      </p>
      {isWebviewUnsupportedByGoogleOAuth(navigator.userAgent) && (
        <div className="mt-3 flex justify-center text-left">
          <WebviewCallout messageType="connect-to-ga4" />
        </div>
      )}
      {state?.connectionToGa4Failed && (
        <p className="mt-3 text-tremor-default text-red-700">
          An error occurred while connecting to Google Analytics.
          <br />
          Please try again or contact support.
        </p>
      )}
      <Button variant="primary" onClick={connectToGa4} className="mt-6">
        Connect Google Analytics
      </Button>
    </SetUpCard>
  );
}
