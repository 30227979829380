import { RiDownload2Line } from '@remixicon/react';
import { CardTitle } from '../card-title';
import MaxItemsPerPageSelect from './MaxItemsPerPageSelect';
import Tabs from './Tabs';
import { Button } from '../buttons';

export default function CardHeader<T extends string>({
  title,
  isAboveTable = false,
  tabs,
  selectedTab,
  onSelectedTabChange,
  isDownloadButtonDisabled = false,
  onDownloadButtonClick,
  maxItemsPerPage,
  onMaxItemsPerPageChange = () => {},
}: {
  title: string;
  isAboveTable?: boolean;
  tabs: Record<T, string>;
  selectedTab: T;
  onSelectedTabChange: (tab: T) => void;
  isDownloadButtonDisabled?: boolean;
  onDownloadButtonClick?: () => void;
  maxItemsPerPage?: number;
  onMaxItemsPerPageChange?: (maxItemsPerPage: number) => void;
}) {
  const hasMoreThanOneTab = Object.keys(tabs).length > 1;
  return (
    <div
      className={`flex flex-col items-center gap-2 sm:flex-row ${isAboveTable ? 'px-1' : ''}`}
    >
      <div className="flex grow items-center gap-2">
        {onDownloadButtonClick && (
          <Button
            icon={RiDownload2Line}
            onClick={onDownloadButtonClick}
            disabled={isDownloadButtonDisabled}
            variant="invisible"
            className="absolute left-7 sm:hidden"
          />
        )}
        <CardTitle title={title} />
        <div className="hidden grow sm:block"></div>
        {onDownloadButtonClick && (
          <Button
            icon={RiDownload2Line}
            onClick={onDownloadButtonClick}
            disabled={isDownloadButtonDisabled}
            variant="invisible"
            className="hidden sm:block"
          />
        )}
        {maxItemsPerPage !== undefined && (
          <div className="absolute right-3 sm:static sm:right-auto">
            <MaxItemsPerPageSelect
              maxItemsPerPage={maxItemsPerPage}
              onMaxItemsPerPageChange={onMaxItemsPerPageChange}
            />
          </div>
        )}
      </div>
      {hasMoreThanOneTab && (
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onSelectedTabChange={onSelectedTabChange}
        />
      )}
    </div>
  );
}
