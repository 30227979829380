import { useQuery } from '@tanstack/react-query';
import { Card } from '@tremor/react';
import { Link, useParams } from 'react-router-dom';
import { useGetUser } from '../../../../requests/api/back-office.requests';
import {
  CardTitle,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  LoadingPage,
  PageContainer,
} from '../../../../components';
import { UserList } from '../../common';
import { formatSubscriptionDate } from '../../../../utils';
import { RiArrowLeftLine } from '@remixicon/react';
import { BackOfficeUserWebsiteCard } from './user-website-card';
import { BackOfficeUserWebsiteConfigurationCard } from './user-website-configuration-card';
import { useState } from 'react';

export default function BackOfficeUserDetailsPage() {
  const { userId: userIdAsString } = useParams();
  const userId = parseInt(userIdAsString!, 10);

  const getUser = useGetUser();

  const user = useQuery({
    queryKey: ['getBackOfficeUser'],
    queryFn: () => getUser(userId),
  });

  const [selectedWebsiteId, setSelectedWebsiteId] = useState<string | null>(null);

  if (!user.isSuccess || user.isRefetching) {
    return <LoadingPage hasError={user.isError} />;
  }

  const isTrial =
    user.data.user.status.subscription === 'trialing' ||
    user.data.user.status.subscription === 'trial ended';

  const trialEndDate = user.data.user.subscription.trialEndDate;
  const endDate = user.data.user.subscription.endDate;

  return (
    <PageContainer>
      <Link
        to="/back-office"
        className="flex items-center space-x-1 text-tremor-default text-tremor-brand"
      >
        <RiArrowLeftLine className="h-5 w-5" />
        <span>Go back to user list</span>
      </Link>
      <div className="grid gap-6">
        <Card>
          <CardTitle title="Overview" />
          <div className="mt-2">
            <UserList users={[user.data.user]} />
          </div>
        </Card>
        <Card>
          <CardTitle title="Subscription" />
          <div className="mt-4">
            <div className="flex space-x-4 text-tremor-default">
              {isTrial && (
                <div>
                  <span className="font-bold">Trial end date: </span>
                  {formatSubscriptionDate({ date: trialEndDate })}
                </div>
              )}
              {!isTrial && endDate && (
                <div>
                  <span className="font-bold">End date: </span>
                  {formatSubscriptionDate({ date: endDate })}
                </div>
              )}
            </div>
            <div className="mt-2">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell small>Plan</TableHeaderCell>
                    <TableHeaderCell small>Status</TableHeaderCell>
                    <TableHeaderCell small>Start date</TableHeaderCell>
                    <TableHeaderCell small>Trial end date</TableHeaderCell>
                    <TableHeaderCell small>End date</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.data.subscriptionHistory.map(subscription => (
                    <TableRow key={subscription.id}>
                      <TableCell small>{subscription.plan.name}</TableCell>
                      <TableCell small>{subscription.status}</TableCell>
                      <TableCell small>
                        {formatSubscriptionDate({ date: subscription.startDate })}
                      </TableCell>
                      <TableCell small>
                        {formatSubscriptionDate({
                          date: subscription.trialEndDate,
                          nullDate: '-',
                        })}
                      </TableCell>
                      <TableCell small>
                        {formatSubscriptionDate({
                          date: subscription.endDate,
                          nullDate: '-',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </Card>
        <div className="grid grid-cols-2 gap-6">
          <div className={selectedWebsiteId ? 'col-span-2 md:col-span-1' : 'col-span-2'}>
            <BackOfficeUserWebsiteCard
              userId={userId}
              onSelectWebsite={setSelectedWebsiteId}
            />
          </div>
          {selectedWebsiteId && (
            <div className="col-span-2 md:col-span-1">
              <BackOfficeUserWebsiteConfigurationCard
                userId={userId}
                websiteId={selectedWebsiteId}
              />
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
}
