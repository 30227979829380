import { RiOrganizationChart } from '@remixicon/react';
import { Button } from '../../../../components';
import { SetUpCard } from '../common';

export default function NoAccountCard() {
  // TODO: use a <Link> component instead of window.open
  function openTutorial() {
    window.open(
      'https://www.linito.io/docs/google-analytics-ga4/setup/create-account',
      '_blank',
    );
  }

  return (
    <SetUpCard>
      <div className="flex justify-center">
        <RiOrganizationChart className="h-14 w-14" />
      </div>
      <h4 className="mt-4 text-tremor-metric font-semibold text-tremor-content-strong">
        Create a GA4 account
      </h4>
      <p className="mt-3 text-tremor-default leading-6 text-tremor-content">
        You currently don't have a Google Analytics account.
        <br />
        Follow this tutorial to create one.
      </p>
      <Button variant="primary" onClick={openTutorial} className="mt-6">
        Open tutorial
      </Button>
    </SetUpCard>
  );
}
