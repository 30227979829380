import { RiCheckLine, RiCloseLargeLine } from '@remixicon/react';
import { Callout, Card } from '@tremor/react';
import { CardTitle, SkeletonLoader } from '../../../../components';
import { UseQueryResult } from '@tanstack/react-query';
import { WebsiteConfiguration } from '../../../../requests/api/back-office.requests';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function LightLink({ anchorText, href }: { anchorText: string; href: string }) {
  return (
    <Link
      to={href}
      target="_blank"
      className="text-tremor-default text-tremor-brand hover:text-tremor-brand-emphasis"
    >
      {anchorText}
    </Link>
  );
}

function Line({
  lastItem,
  title,
  status,
  isLoading,
  children,
  hiddenDescription,
}: PropsWithChildren<{
  lastItem?: boolean;
  title: string;
  status: 'ok' | 'bad' | 'medium';
  isLoading: boolean;
  hiddenDescription?: string;
}>) {
  return (
    <li className="relative flex gap-x-3">
      <div
        className={classNames(
          lastItem ? 'h-6' : '-bottom-6',
          'absolute left-0 top-0 flex w-6 justify-center',
        )}
      >
        <span className="w-px bg-tremor-border" aria-hidden={true} />
      </div>
      <div className="flex items-start space-x-2.5">
        <div className="relative flex size-6 flex-none items-center justify-center bg-tremor-background">
          {status === 'ok' ? (
            isLoading ? (
              <SkeletonLoader className="size-5" />
            ) : (
              <RiCheckLine className="size-5 text-green-600" aria-hidden={true} />
            )
          ) : status === 'bad' ? (
            isLoading ? (
              <SkeletonLoader className="size-5" />
            ) : (
              <RiCloseLargeLine className="size-5 text-red-600" aria-hidden={true} />
            )
          ) : isLoading ? (
            <SkeletonLoader className="size-2.5 rounded-tremor-full" />
          ) : (
            <div
              className="size-2.5 rounded-tremor-full bg-yellow-600 ring-4 ring-tremor-background"
              aria-hidden={true}
            />
          )}
        </div>
        <div>
          <p className="mt-0.5 text-tremor-default font-medium text-tremor-content-strong">
            {isLoading ? <SkeletonLoader hiddenText={title} /> : title}
          </p>
          {children && (
            <p className="mt-0.5 text-tremor-default leading-6 text-tremor-content">
              {isLoading ? <SkeletonLoader hiddenText={hiddenDescription} /> : children}
            </p>
          )}
        </div>
      </div>
    </li>
  );
}

export default function DiagnosticCard({
  websiteConfiguration,
}: {
  websiteConfiguration: UseQueryResult<WebsiteConfiguration>;
}) {
  return (
    <Card>
      <CardTitle title="Diagnostic" />
      <div className="mt-4">
        {websiteConfiguration.isSuccess && (
          <ul className="space-y-6">
            {websiteConfiguration.data.diagnostic.hasWebDataStreamBeenFound ? (
              <Line
                title="Web data stream found"
                status="ok"
                isLoading={websiteConfiguration.isRefetching}
              />
            ) : (
              <Line
                title="Web data stream not found"
                hiddenDescription="No web data stream was found associated with this website. Create a data stream with your website URL to track your traffic."
                status="bad"
                isLoading={websiteConfiguration.isRefetching}
              >
                No web data stream was found associated with this website.{' '}
                <LightLink
                  anchorText="Create a web data stream"
                  href={websiteConfiguration.data.diagnostic.dataStreamConfigurationUrl}
                />{' '}
                with your website URL to track your traffic.
              </Line>
            )}
            {websiteConfiguration.data.diagnostic.isRetentionTimeSetUpCorrectly ? (
              <Line
                title="Retention time correctly set"
                status="ok"
                isLoading={websiteConfiguration.isRefetching}
              />
            ) : (
              <Line
                title="Retention time incorrectly set"
                hiddenDescription="Your traffic data is set to be kept for 2 months only. Update your configuration to retain it for 14 months."
                status="medium"
                isLoading={websiteConfiguration.isRefetching}
              >
                Your traffic data is set to be kept for 2 months only.{' '}
                <LightLink
                  anchorText="Update your configuration"
                  href={
                    websiteConfiguration.data.diagnostic.retentionTimeConfigurationUrl
                  }
                />{' '}
                to retain it for 14 months.
              </Line>
            )}
            {websiteConfiguration.data.diagnostic.hasReceivedEventsInLast30Minutes ? (
              <Line
                lastItem
                title="Traffic received"
                status="ok"
                isLoading={websiteConfiguration.isRefetching}
              />
            ) : (
              <Line
                lastItem
                title="No traffic received"
                hiddenDescription="No data has been received in the last 30 minutes. If you have visited your website during this period, please check that your tracking code is correctly configured."
                status="medium"
                isLoading={websiteConfiguration.isRefetching}
              >
                No data has been received in the last 30 minutes. If you have visited your
                website during this period, please{' '}
                <LightLink
                  anchorText="check that your tracking code is correctly configured"
                  href="https://www.linito.io/docs/google-analytics-ga4/setup/tracking-code"
                />
                .
              </Line>
            )}
          </ul>
        )}
        {websiteConfiguration.isPending && <SkeletonLoader className="h-44" />}
        {websiteConfiguration.isError && (
          <Callout title="An error has occurred" color="red">
            An error occurred while retrieving the data. Try refreshing the page.
          </Callout>
        )}
      </div>
    </Card>
  );
}
