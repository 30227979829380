export function formatRetentionTime(retentionTime: string): string {
  switch (retentionTime) {
    case 'TWO_MONTHS':
      return '2 months';
    case 'FOURTEEN_MONTHS':
      return '14 months';
    case 'TWENTY_SIX_MONTHS':
      return '26 months';
    case 'THIRTY_EIGHT_MONTHS':
      return '38 months';
    case 'FIFTY_MONTHS':
      return '50 months';
    case 'RETENTION_DURATION_UNSPECIFIED':
      return 'Unspecified';
    default:
      return retentionTime;
  }
}
