import { useQuery } from '@tanstack/react-query';
import { Callout } from '@tremor/react';
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { CenteredSpinner } from '../../../components';
import { useGetWebsites } from '../../../requests/api/website.requests';
import { Ga4ConnectorCard } from './ga4-connector-card';
import {
  saveLastSelectedWebsiteId,
  getDefaultWebsiteId,
} from './section-container.utils';
import { SectionContext } from './section-container.interfaces';
import { DateRange } from '../../../requests/api/common.interfaces';
import { NoAccountCard } from './no-account-card';
import { NoPropertyCard } from './no-property-card';

export default function SectionContainer() {
  const getWebsites = useGetWebsites();
  const websites = useQuery({
    queryKey: ['websites'],
    queryFn: () => getWebsites(),
    refetchOnWindowFocus: false,
  });
  const [websiteId, setWebsiteId] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<DateRange>({
    type: 'shortcut',
    name: 'last7days',
  });

  function onWebsiteIdChange(websiteId: string) {
    saveLastSelectedWebsiteId(websiteId);
    setWebsiteId(websiteId);
  }

  useEffect(() => {
    const defaultWebsiteId = getDefaultWebsiteId(websites.data?.websites);
    setWebsiteId(defaultWebsiteId);
  }, [websites.data]);

  if (websites.isPending) return <CenteredSpinner />;

  if (websites.isError)
    return (
      <Callout title="An error has occurred" color="red">
        An error occurred while retrieving the list of your websites. Try refreshing the
        page.
      </Callout>
    );

  if (!websites.data.isUserConnectedToGoogleAnalytics) {
    return <Ga4ConnectorCard />;
  }

  if (websites.data.websitesGroupedByGa4Account.length === 0) {
    return <NoAccountCard />;
  }

  if (websites.data.websites.length === 0) {
    return <NoPropertyCard />;
  }

  if (!websiteId) return null;

  const context: SectionContext = {
    websiteId,
    websites: websites.data.websites,
    dateRange,
    setWebsiteId: onWebsiteIdChange,
    setDateRange,
  };

  return <Outlet context={context} />;
}
