import { useEffect } from 'react';
import { useGetWebsite } from '../../../../requests/api/website.requests';
import { useQuery } from '@tanstack/react-query';

export function useWebsiteConfiguration({
  websiteId,
  queryKey,
}: {
  websiteId: string;
  queryKey: string;
}) {
  const getWebsite = useGetWebsite();
  const block = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      return getWebsite({
        websiteId,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId]);

  return block;
}
