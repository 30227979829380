import { Callout, Card } from '@tremor/react';
import { useTrafficBlock } from '../common';
import {
  formatPercentage,
  formatSecondsInMinutes,
  usePagination,
} from '../../../../utils';
import {
  CardHeader,
  ComparisonBadge,
  NumberValue,
  PaginationBar,
  PercentageBar,
  SkeletonLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '../../../../components';
import { Comparison } from '../../../../requests/api/traffic.requests';
import { useState } from 'react';
import { RiFilterLine } from '@remixicon/react';
import { getFilterKeyFromBlockName } from './pages-card.utils';
import { DateRange, Filter } from '../../../../requests/api/common.interfaces';

export type PageBlockName = 'pages';

const tabs: Record<PageBlockName, string> = {
  pages: 'All pages',
};

interface PagesCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
  onAddFilter: (filter: Filter) => void;
}

export default function PagesCard({
  websiteId,
  dateRange,
  filters,
  comparison,
  onAddFilter,
}: PagesCardProps) {
  const [blockName, setBlockName] = useState<PageBlockName>('pages');
  const [maxItemsPerPage, setMaxItemsPerPage] = useState(10);

  const block = useTrafficBlock({
    queryKey: 'pagesBlock',
    blockName,
    websiteId,
    dateRange,
    filters,
    comparison,
  });

  const { paginatedItems, currentPageIndex, setCurrentPageIndex, numberOfPages } =
    usePagination({
      items: block.data?.items,
      maxItemsPerPage,
    });

  const addFilter = (value: string) => {
    const filter: Filter = {
      key: getFilterKeyFromBlockName(blockName),
      matchKey: 'equals',
      value,
    };

    onAddFilter(filter);
  };

  return (
    <Card>
      <CardHeader
        title="Pages"
        isAboveTable
        tabs={tabs}
        selectedTab={blockName}
        onSelectedTabChange={setBlockName}
        maxItemsPerPage={maxItemsPerPage}
        onMaxItemsPerPageChange={setMaxItemsPerPage}
      />
      <div className="mt-4 overflow-x-auto">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell textAlign="left">Path</TableHeaderCell>
              <TableHeaderCell />
              <TableHeaderCell>Views</TableHeaderCell>
              <TableHeaderCell>Engagement rate</TableHeaderCell>
              <TableHeaderCell>Time on page</TableHeaderCell>
              <TableHeaderCell>Visitors</TableHeaderCell>
              <TableHeaderCell>Views (%)</TableHeaderCell>
            </TableRow>
          </TableHead>
          {block.isSuccess && (
            <TableBody>
              {paginatedItems.map(item => (
                <TableRow key={item.path}>
                  <TableCell textAlign="left" bold dark>
                    <span className="hover-container flex items-center">
                      {block.isRefetching ? (
                        <SkeletonLoader hiddenText={item.path} className="inline" />
                      ) : (
                        item.path
                      )}
                      <span className="grow" />
                      <span className="hover-element invisible">
                        <button title="Filter on this page">
                          <RiFilterLine
                            className="h-4 w-4"
                            onClick={() => addFilter(item.path)}
                          />
                        </button>
                      </span>
                    </span>
                  </TableCell>
                  <TableCell>
                    <ComparisonBadge values={item.views} isLoading={block.isRefetching} />
                  </TableCell>
                  <TableCell>
                    <NumberValue values={item.views} isLoading={block.isRefetching} />
                  </TableCell>
                  <TableCell>
                    <NumberValue
                      values={item.engagementRate}
                      valueFormatter={formatPercentage}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberValue
                      values={item.timeOnPage}
                      valueFormatter={formatSecondsInMinutes}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                  <TableCell>
                    <NumberValue values={item.visitors} isLoading={block.isRefetching} />
                  </TableCell>
                  <TableCell>
                    <PercentageBar
                      values={item.views}
                      maxValue={block.data.maxViewsPerItem}
                      isLoading={block.isRefetching}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
      {block.isSuccess && numberOfPages > 1 && (
        <div className="mt-2">
          <PaginationBar
            numberOfPages={numberOfPages}
            currentPageIndex={currentPageIndex}
            onCurrentPageIndexChange={setCurrentPageIndex}
          />
        </div>
      )}
      {block.isPending && <SkeletonLoader className="mt-2 h-36" />}
      {block.isError && (
        <Callout title="An error has occurred" color="red">
          An error occurred while retrieving the data. Try refreshing the page.
        </Callout>
      )}
    </Card>
  );
}
