export function formatDataStreamType(type: string): string {
  switch (type) {
    case 'WEB_DATA_STREAM':
      return 'Web';
    case 'ANDROID_APP_DATA_STREAM':
      return 'Android';
    case 'IOS_APP_DATA_STREAM':
      return 'iOS';
    case 'DATA_STREAM_TYPE_UNSPECIFIED':
      return 'Unspecified';
    default:
      return type;
  }
}
