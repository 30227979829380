import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { CurrentUserContext, signInRoutePath, signUpRoutePath } from './auth';
import { useEffect } from 'react';
import {
  MainPage,
  SignUpPage,
  SignInPage,
  NotFoundPage,
  BackOfficePage,
  ForbiddenPage,
  GoogleCallbackPage,
  BackOfficeUserDetailsPage,
  AccountSettingsPage,
  TrafficSection,
  SubscriptionCallbackPage,
  UnsubscriptionCallbackPage,
  ResubscriptionCallbackPage,
  GoalSection,
  SubscriptionWithCodePage,
  ConfigurationSection,
} from './pages';
import { LoadingPage, ProtectedElement } from './components';
import { isDemoMode } from './configuration';
import { setExternalServicesUser } from './external-services';
import { useGetCurrentUser } from './requests/api/users.requests';
import { useQuery } from '@tanstack/react-query';

export default function App() {
  const getCurrentUser = useGetCurrentUser();

  const currentUser = useQuery({
    queryKey: ['currentUser'],
    queryFn: getCurrentUser,
  });

  useEffect(() => {
    setExternalServicesUser(currentUser.data ?? null);
  }, [currentUser.data]);

  async function refreshCurrentUser() {
    await currentUser.refetch();
  }

  if (!currentUser.isSuccess) {
    return <LoadingPage hasError={currentUser.isError} />;
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: currentUser.data,
        refreshCurrentUser,
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/traffic" replace />} />
          <Route path="/" element={<ProtectedElement element={<MainPage />} />}>
            <Route path="/traffic" element={<TrafficSection />} />
            <Route path="/goal" element={<GoalSection />} />
            <Route path="/configuration" element={<ConfigurationSection />} />
          </Route>
          {!isDemoMode() && (
            <Route
              path="/account-settings"
              element={<ProtectedElement element={<AccountSettingsPage />} />}
            />
          )}
          {!isDemoMode() && (
            <Route
              path="/account-settings/activation-key"
              element={<ProtectedElement element={<SubscriptionWithCodePage />} />}
            />
          )}
          {!isDemoMode() && (
            <Route
              path="/subscription-callback"
              element={<ProtectedElement element={<SubscriptionCallbackPage />} />}
            />
          )}
          {!isDemoMode() && (
            <Route
              path="/unsubscription-callback"
              element={<ProtectedElement element={<UnsubscriptionCallbackPage />} />}
            />
          )}
          {!isDemoMode() && (
            <Route
              path="/resubscription-callback"
              element={<ProtectedElement element={<ResubscriptionCallbackPage />} />}
            />
          )}
          {!isDemoMode() && (
            <Route
              path="/back-office"
              element={
                <ProtectedElement
                  element={<BackOfficePage />}
                  permission="back-office:read"
                />
              }
            />
          )}
          {!isDemoMode() && (
            <Route
              path="/back-office/users/:userId"
              element={
                <ProtectedElement
                  element={<BackOfficeUserDetailsPage />}
                  permission="back-office:read"
                />
              }
            />
          )}
          {!isDemoMode() && <Route path={signUpRoutePath} element={<SignUpPage />} />}
          {!isDemoMode() && <Route path={signInRoutePath} element={<SignInPage />} />}
          <Route path="/google/callback" element={<GoogleCallbackPage />} />
          <Route path="/forbidden" element={<ForbiddenPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </CurrentUserContext.Provider>
  );
}
