import { Callout, Card, List, ListItem } from '@tremor/react';
import { CardTitle, SkeletonLoader } from '../../../../components';
import { UseQueryResult } from '@tanstack/react-query';
import { WebsiteConfiguration } from '../../../../requests/api/website.requests';
import { formatRetentionTime } from '../../../../utils';
import { RiCodeSSlashLine, RiPencilLine } from '@remixicon/react';
import { isDemoMode } from '../../../../configuration';

function TextValue({ value, isLoading }: { value: string | null; isLoading: boolean }) {
  const formattedValue = value ?? '-';

  if (isLoading) {
    return <SkeletonLoader className="inline-block" hiddenText={formattedValue} />;
  }

  return <span>{formattedValue}</span>;
}

function LinkValue({ value, isLoading }: { value: string | null; isLoading: boolean }) {
  const formattedValue = value ?? '-';

  if (isLoading) {
    return <SkeletonLoader className="inline-block" hiddenText={formattedValue} />;
  }

  return value === null ? (
    <span>-</span>
  ) : (
    <a
      href={formattedValue}
      className="text-tremor-brand"
      target="_blank"
      rel="noreferrer"
    >
      {formattedValue}
    </a>
  );
}

function ListItemLabel({
  name,
  hint,
  icon: Icon,
  href,
}: {
  name: string;
  hint: string;
  icon: React.ElementType;
  href: string;
}) {
  // TODO: use a <Link> component instead of window.open. Currently no used because <a> has no "disabled" attribute
  function openLink() {
    window.open(href, '_blank');
  }

  return (
    <span className="flex items-center space-x-2 font-medium">
      <span>{name}</span>
      <button
        title={hint}
        disabled={isDemoMode()}
        className="hover-element invisible disabled:cursor-not-allowed"
        onClick={openLink}
      >
        <Icon className="h-4 w-4" />
      </button>
    </span>
  );
}

export default function SummaryCard({
  websiteConfiguration,
}: {
  websiteConfiguration: UseQueryResult<WebsiteConfiguration>;
}) {
  return (
    <Card>
      <CardTitle title="Summary" />
      <div className="mt-4">
        {websiteConfiguration.isSuccess && (
          <List>
            <ListItem className="hover-container">
              <ListItemLabel
                name="Time zone"
                hint="Edit"
                icon={RiPencilLine}
                href={websiteConfiguration.data.summary.timeZoneConfigurationUrl}
              />
              <TextValue
                value={websiteConfiguration.data.summary.timeZone}
                isLoading={websiteConfiguration.isRefetching}
              />
            </ListItem>
            {websiteConfiguration.data.summary.websiteURLsAndTrackingCodeIds.map(
              ({ url, dataStreamConfigurationUrl, trackingCodeId }) => (
                <>
                  <ListItem className="hover-container">
                    <ListItemLabel
                      name="Website URL"
                      hint="Edit"
                      icon={RiPencilLine}
                      href={dataStreamConfigurationUrl}
                    />
                    <LinkValue
                      value={url}
                      isLoading={websiteConfiguration.isRefetching}
                    />
                  </ListItem>
                  <ListItem className="hover-container">
                    <ListItemLabel
                      name="Tracking code ID"
                      hint="Add tracking code"
                      href="https://www.linito.io/docs/google-analytics-ga4/setup/tracking-code"
                      icon={RiCodeSSlashLine}
                    />
                    <TextValue
                      value={trackingCodeId}
                      isLoading={websiteConfiguration.isRefetching}
                    />
                  </ListItem>
                </>
              ),
            )}
            <ListItem className="hover-container">
              <ListItemLabel
                name="Retention time"
                hint="Edit"
                icon={RiPencilLine}
                href={websiteConfiguration.data.summary.retentionTimeConfigurationUrl}
              />
              <TextValue
                value={formatRetentionTime(
                  websiteConfiguration.data.summary.minimumDataRetentionTime,
                )}
                isLoading={websiteConfiguration.isRefetching}
              />
            </ListItem>
            <ListItem>
              <span className="font-medium">Traffic received (last 30 min)</span>
              <TextValue
                value={
                  websiteConfiguration.data.summary.hasReceivedEventsInLast30Minutes
                    ? 'yes'
                    : 'no'
                }
                isLoading={websiteConfiguration.isRefetching}
              />
            </ListItem>
          </List>
        )}
        {websiteConfiguration.isPending && <SkeletonLoader className="h-44" />}
        {websiteConfiguration.isError && (
          <Callout title="An error has occurred" color="red">
            An error occurred while retrieving the data. Try refreshing the page.
          </Callout>
        )}
      </div>
    </Card>
  );
}
